import moment from "moment";
import "moment-duration-format";
import posthog from "posthog-js";
import PhoneNumber from "awesome-phonenumber";
import { EntityType } from "../redux/action/CurrentActions";
import { logDebug, logError } from "../service/ServiceUtil";
import { getLocation, isTestEnv, objectToQueryString, shareCodesFromQuery } from "./URLUtils";

export const isWWW =
  typeof window !== "undefined" &&
  (window.location.hostname.toLowerCase().startsWith("www.") ||
    window.location.hostname.toLowerCase().startsWith("www-test."));

export const StartupProps = isTestEnv
  ? {
      ASSISTANT_USER_ID: 333,
    }
  : {
      ASSISTANT_USER_ID: 4505,
    };

export const isSSR = typeof window === "undefined";

export const isPixelMixerAccount =
  typeof window !== "undefined" &&
  window.location.hostname.toLowerCase().startsWith("pixelmixer.pixelmixer.");

// Object.entries polyfill for IE/Edge
if (!Object.entries) {
  Object.entries = function (obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}

export const formatDate = (longValue) => {
  var date = new Date(longValue);
  return moment(date).calendar();
};

export const localeDate = (longValue) => {
  var date = new Date(longValue);
  return moment(date).format("LLLL");
};

export const fromNow = (longValue) => {
  var date = new Date(longValue);
  return moment(date).fromNow();
};

export const formatSecondsToTime = (seconds) => {
  return seconds < 60
    ? moment.duration(seconds, "seconds").format("mm:ss", { trim: false })
    : moment.duration(seconds, "seconds").format("HH:mm:ss");
};

export const formatTimeToSeconds = (time) => {
  if (time.includes(":")) {
    if (time.split(":").length == 2) {
      return moment.duration(`00:${time}`).asSeconds();
    } else {
      return moment.duration(time).asSeconds();
    }
  }
  return null;
};

export const addMonthsToDate = (date, numMonths) => {
  let newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + numMonths);
  return newDate;
};

export const addSecondsToDate = (date, numSeconds) => {
  const newDate = new Date(date);
  newDate.setSeconds(newDate.getSeconds() + numSeconds);
  return newDate;
};

export const sortListBy = (list, sortFieldName, reverse) => {
  if (typeof list !== "undefined") {
    let sortedList = list.slice();
    sortedList.sort(function (a, b) {
      let sortFieldA =
        typeof a[sortFieldName] === "string"
          ? a[sortFieldName].toLowerCase()
          : a[sortFieldName];
      let sortFieldB =
        typeof b[sortFieldName] === "string"
          ? b[sortFieldName].toLowerCase()
          : b[sortFieldName];
      return reverse
        ? sortFieldB > sortFieldA
          ? 1
          : -1
        : sortFieldA > sortFieldB
        ? 1
        : -1;
    });
    return sortedList;
  } else {
    return [];
  }
};

export const randomString = (length) => {
  let value = "";
  let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  for (var i = 0; i < length; i++) {
    value += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return value;
};

export const getWebsocketUrl = (relativePath) => {
  var loc = typeof window !== "undefined" && window.location,
    new_uri;
  if (loc.protocol === "https:") {
    new_uri = "wss:";
  } else {
    new_uri = "ws:";
  }
  // new_uri += "//" + 'test.local.pixelmixer.com:8080/pixelMixer';
  // new_uri = "wss://test.pixelmixer.com:4443";
  // new_uri = "ws://test.local.pixelmixer.com";
  new_uri += "//" + loc.host;
  return (new_uri += relativePath);
};

export const containsKey = (object, key) => {
  for (var keyName in object) {
    if (keyName === key) return true;
  }
  return false;
};

export const containsValue = (object, value) => {
  for (var keyName in object) {
    if (object[keyName] === value) return true;
  }
  return false;
};

//Careful because isNaN(null) === false..
export const toInt = (value) => {
  return typeof value !== "undefined" && value !== null
    ? parseInt(value)
    : null;
};

export const objectEquals = (x, y) => {
  "use strict";

  if (x === null || x === undefined || y === null || y === undefined) {
    return x === y;
  }
  // after this just checking type of one would be enough
  if (x.constructor !== y.constructor) {
    return false;
  }
  // if they are functions, they should exactly refer to same one (because of closures)
  if (x instanceof Function) {
    return x === y;
  }
  // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
  if (x instanceof RegExp) {
    return x === y;
  }
  if (x === y || x.valueOf() === y.valueOf()) {
    return true;
  }
  if (Array.isArray(x) && x.length !== y.length) {
    return false;
  }

  // if they are dates, they must had equal valueOf
  if (x instanceof Date) {
    return false;
  }

  // if they are strictly equal, they both need to be object at least
  if (!(x instanceof Object)) {
    return false;
  }
  if (!(y instanceof Object)) {
    return false;
  }

  // recursive object equality check
  var p = Object.keys(x);
  return (
    Object.keys(y).every(function (i) {
      return p.indexOf(i) !== -1;
    }) &&
    p.every(function (i) {
      return objectEquals(x[i], y[i]);
    })
  );
};

export const generateChatConversationId = (fromUserId, toUserId) => {
  return fromUserId < toUserId
    ? fromUserId + "+" + toUserId
    : toUserId + "+" + fromUserId;
};

export const isVideoFile = (file) => {
  return file && file.type && file.type.startsWith("video");
};

export const isImageFile = (file) => {
  return file && file.type && file.type.startsWith("image");
};

export const validateEmail = (email) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhone = (phone) => {
  var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return re.test(String(phone));
};

export const copyElToClipboard = (el) => {
  let copied = false;
  if (typeof window !== "undefined" && window.deviceAPI?.isIOS) {
    copied = iosCopyElToClipboard(el);
  } else {
    el.select();
    try {
      copied = document.execCommand("copy");
    } catch (err) {}
  }
  if (!copied) unselectClipboard();
  return copied;
};

export const iosCopyElToClipboard = (el) => {
  const oldContentEditable = el.contentEditable,
    oldReadOnly = el.readOnly,
    range = document.createRange();

  el.contenteditable = true;
  el.readonly = false;
  range.selectNodeContents(el);

  const s = typeof window !== "undefined" && window.getSelection();
  s.removeAllRanges();
  s.addRange(range);

  el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

  el.contentEditable = oldContentEditable;
  el.readOnly = oldReadOnly;

  try {
    return document.execCommand("copy");
  } catch (err) {
    return false;
  }
};

const unselectClipboard = () => {
  const s = typeof window !== "undefined" && window.getSelection();
  s.removeAllRanges();
};

export const extractRequestPath = (fullPath) => {
  const originSlash = /^https?:\/\/[^/]+\//i;
  return "/" + fullPath.replace(originSlash, "");
};

export const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, "g"), replace);
};

export const cleanPhoneNumber = (phone) => {
  return phone ? phone.replace(new RegExp("([^0-9])", "g"), "") : undefined;
};

export const splitCamelCase = (string) => {
  if (string) {
    let split = string && string.replace(/([a-z])([A-Z])/g, "$1 $2");
    return split.charAt(0).toUpperCase() + split.slice(1);
  }
};

export const deviceName = () => {
  return (
    typeof window !== "undefined" &&
    window.deviceAPI &&
    window?.deviceAPI?.deviceName
  );
};

export const isTouchSupported = () => {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
};

export const initIntercom = (userSession) => {
  const { user, account } = userSession;
  try {
    if (
      getLocation().query.chromeless !== "true" &&
      getLocation().query.galleryMode !== "true" &&
      getLocation().query.playerOnly !== "true"
    )
      typeof window !== "undefined" &&
        window.Intercom &&
        window.Intercom("boot", {
          app_id: window.INTERCOM_APP_ID,
          email: user.emailAddress,
          user_id: user.id,
          user_hash: userSession.intercomId,
          created_at: user.createdDate / 1000,
          phone: user.phone,
          company: {
            company_id: account.id,
            created_at: account.createdDate / 1000,
            name: account.name,
            website: account.directUrl,
          },
          hide_default_launcher: true
            // !account?.intercomChatEnabled || window?.deviceAPI.isPhone,
        });
  } catch (e) {
    logError("initIntercom", userSession, e);
  }
};

export const showIntercom = (account) => {
  return;
  typeof window?.Intercom !== "undefined" &&
    window.Intercom("update", {
      hide_default_launcher:
        !account?.intercomChatEnabled || window?.deviceAPI.isPhone,
    });
};

export const hideIntercom = () => {
  return;
  typeof window?.Intercom !== "undefined" &&
    window.Intercom("update", {
      hide_default_launcher: true,
    });
};

export const closeIntercom = () => {
  typeof window?.Intercom !== "undefined" && window.Intercom("hide");
};

export const trackIntercomEvent = (eventName, metadata) => {
  typeof window?.Intercom !== "undefined" &&
    window.Intercom("trackEvent", eventName, metadata);
};

export const showIntercomTour = (tourId) => {
  typeof window?.Intercom !== "undefined" &&
    window.Intercom("startTour", tourId);
};

export const showIntercomNewMessage = (message) => {
  typeof window?.Intercom !== "undefined" &&
    window.Intercom("showNewMessage", message);
};

export const shutdownIntercom = () => {
  typeof window !== "undefined" && window.Intercom("shutdown");
};

export const showIntercomArticle = (articleId) => {
  typeof window?.Intercom !== "undefined" &&
    window.Intercom("showArticle", articleId);
};

export const openIntercomArticle = (articleId) => {
  openUrl(`https://help.pixelmixer.com/en/articles/${articleId}`);
};

export const openUrl = (url) => {
  window.open(url, "_blank");
};

export const updateSearchPageUrl = (
  contextRoot,
  contentId,
  searchString,
  playtime
) => {
  if (!contextRoot) throw "Context root required.";
  const queryObject = {};
  if (searchString) queryObject.search = searchString;
  push(`${contextRoot}/${contentId || ""}`, queryObject);
};

const updateUrl = (location) => {
  //If the video/page isnt changing, replace the current url so back button works as expected:
  if (location.pathname === window.location.pathname)
    window.router.navigate(
      location.pathname +
        (location.search ? location.search : "") +
        (location.hash ? `#${location.hash}` : ""),
      { replace: true }
    );
  else
    window.router.navigate(
      (location.pathname || "/") +
        (location.search ? location.search : "") +
        (location.hash ? `#${location.hash}` : "")
    );
};

export const push = (pathname, queryObject = {}, hash) => {
  const completeQueryObject = { ...shareCodesFromQuery(), ...queryObject };
  const location = {
    pathname,
    search:
      Object.keys(completeQueryObject).length > 0
        ? objectToQueryString(completeQueryObject)
        : "",
    hash,
  };
  updateUrl(location);
};

export const updatePlaytimePageUrl = (playtime) => {
  logDebug("updatePlaytimePageUrl", playtime);
  const queryObject = typeof window !== "undefined" && {
    ...Object.fromEntries(
      new URLSearchParams(window.location.search).entries()
    ),
    time: playtime,
  };
  const location = getLocation();
  location.search = objectToQueryString(queryObject);
  updateUrl(location);
};

export const openSupportUrl = (pageName) => {
  typeof window !== "undefined" &&
    openUrl(`https://help.pixelmixer.com/en/articles/${pageName}`);
};

export const getPhoneNumber = (number, country = "US", format = "national") => {
  const phoneNumber = number && new PhoneNumber(number, country);
  return phoneNumber ? phoneNumber.getNumber("national") : "";
};

export const isRecentlyUpdated = (date) => {
  return date && addMonthsToDate(new Date(), -1) < date;
};

export const getwindowDimensions = () => {
  const body = document.body,
    html = document.documentElement;
  const width = Math.max(
    body.scrollWidth,
    body.offsetWidth,
    html.clientWidth,
    html.scrollWidth,
    html.offsetWidth
  );
  const height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
  return {
    width,
    height,
  };
};

export const stripHTMLTags = (inputHTML) => {
  return inputHTML ? inputHTML.replace(/<\/?[^>]+(>|$)/g, "") : "";
};

export const getVideoDuration = async (videoUrl) => {
  if (videoUrl) {
    const videoEl = document.createElement("video");
    videoEl.src = videoUrl;
    while (videoEl && (!videoEl.duration || videoEl.duration === Infinity)) {
      await new Promise((r) => setTimeout(r, 1000));
      if (videoEl) videoEl.currentTime = 10000000 * Math.random();
    }
    const duration = videoEl.duration;
    videoEl.remove();
    return duration;
  }
};

export const zeroPad = (num, places) => String(num).padStart(places, "0");

export const selectFileForUpload = (contentType, multiple) => {
  return new Promise((resolve) => {
    let input = document.createElement("input");
    input.type = "file";
    input.multiple = multiple;
    input.accept = contentType;

    input.onchange = () => {
      let files = Array.from(input.files);
      if (multiple) resolve(files);
      else resolve(files[0]);
    };

    input.click();
  });
};

export const hasVideoInput = async () =>
  !!(await navigator.mediaDevices.enumerateDevices()).find(
    (e) => e.kind === "videoinput"
  );

export const getRecordingImportName = (entityType) => {
  switch (entityType) {
    case EntityType.ZoomRecording:
      return "Zoom";
    case EntityType.WebexRecording:
      return "Webex";
    case EntityType.RingCentralRecording:
      return "RingCentral";
    case EntityType.GoogleDriveFile:
      return "Google Drive";
    default:
      return "";
  }
};

export const getRecordingImportEntityType = () => {
  const pathname = window.location.pathname;
  return pathname.includes("/zoom")
    ? EntityType.ZoomRecording
    : pathname.includes("/webex")
    ? EntityType.WebexRecording
    : pathname.includes("/ringCentral")
    ? EntityType.RingCentralRecording
    : pathname.includes("/teams")
    ? EntityType.TeamsRecording
    : EntityType.GoogleDriveFile;
};

export const hash = (string) => {
  const seed = 0;
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < string.length; i++) {
    ch = string.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export const getPartOfDay = () => {
  const now = moment();
  const currentHour = now.local().hour();
  if (currentHour >= 12 && currentHour <= 17) return "afternoon";
  else if (currentHour >= 18) return "evening";
  else return "morning";
};

export const formatCurrency = (amount) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(amount || 0);
};

export const removeEmptyElements = (obj) => {
  return obj
    ? Object.fromEntries(
        Object.entries(obj)
          .filter(([_, v]) => v != null)
          .map(([k, v]) => [
            k,
            v === Object(v) && !Array.isArray(v) ? removeEmptyElements(v) : v,
          ])
      )
    : {};
};

export const onlyUnique = (value, index, array) => {
  return array.indexOf(value) === index;
};

export const isObject = (o) => typeof o === "object" && !Array.isArray(o);

export const getPlural = (term, count) => {
  return `${term + (count === 1 ? "" : "s")}`;
};

export const extractListOfUrls = (text) => {
  if (text) {
    const regex =
      /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;
    return text.match(regex);
  }
};

export const extractTextFromXML = (xmlString) => {
  if (xmlString) {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(xmlString, "application/xml");
      const errors = doc.getElementsByTagName("parsererror");
      while (errors.length > 0) {
        errors[0].parentNode.removeChild(errors[0]);
      }
      return doc.documentElement.textContent;
    } catch (e) {
      logError(
        "extractTextFromXML: Unable to parse xmlString",
        { xmlString },
        e
      );
    }
  }
};

export const identifyPostHogUser = (user) => {
  posthog.identify(user.emailAddress, {
    email: user.emailAddress,
    name: user.fullName,
  });
};

export const cleanObject = object => Object.fromEntries(
  Object.entries(object).filter(([key, value]) => value !== undefined)
);









