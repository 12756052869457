import { ModalName, UiMode } from "../redux/action/UIActions";
import { logError } from "../service/ServiceUtil";
import { toInt } from "./Utils";


export const isTestEnv =
  typeof window !== "undefined" &&
  window.location.hostname.toLowerCase().indexOf(".local.") > 0;

export let isTestAgent = false;
export const getIsTestAgent = () => {
  if (!isTestAgent)
    isTestAgent =
      getQueryParams().get("test") === "" ||
      getQueryParams().get("test") === "true" ||
      (typeof navigator !== "undefined" &&
        navigator.userAgent?.includes("isTestAgent"));
  return isTestAgent;
};
  
export const getRedirectUrl = () => {
  const redirect = getQueryParams()["redirect"] || "/";
  return new URL(window.location.origin + redirect);
};

export const getRelativePath = (urlString) => {
  const url = new URL(urlString);
  return url.pathname + url.search;
};
export const getModalNameFromPathname = () => {
  const pathname = getLocation().pathname;
  for (var modalName of Object.keys(ModalName)) {
    if (pathname.endsWith(ModalName[modalName])) return ModalName[modalName];
  }
};
export const isModalNameInPathname = (modalName) => {
  return getLocation().pathname.endsWith(modalName);
};
export const removeLevelFromPathname = () => {
  const pathname = getLocation().pathname;
  return pathname.lastIndexOf("/") >= 0
    ? pathname.substring(0, pathname.lastIndexOf("/"))
    : pathname;
};

export const queryObjectFromSearchString = (search = "") => {
  try {
    return search
      ? Object.fromEntries(new URLSearchParams(search).entries())
      : {};
  } catch (e) {
    logError("queryObjectFromSearchString", { search }, e);
    return {};
  }
};


export const isOAuthUrl = () =>
  window.location.pathname.toLowerCase().indexOf("oauth") >= 0;export const getLocation = () => {
  return {
    ...window.location,
    query: getQueryParams(),
  };
};
export const getQueryParams = () => {
  return {
    ...Object.fromEntries(
      typeof window === "undefined"
        ? {}
        : new URLSearchParams(window.location.search).entries()
    ),
    getInt: (param) => typeof window !== "undefined" &&
      toInt(new URLSearchParams(window.location.search).get(param)),
    getBoolean: (param) => {
      const value = typeof window !== "undefined" &&
        new URLSearchParams(window.location.search).get(param);
      return !value ? null : value === "true";
    },
    get: (param) => typeof window !== "undefined" &&
      new URLSearchParams(window.location.search).get(param),
  };
};
export const shareCodesFromQuery = (time) => {
  const redirect = getQueryParams()["redirect"];
  const redirectParams = redirect ? getQueryParamsFromPath(redirect) : {};
  const queryObj = Object.assign(getQueryParams(), redirectParams);
  let keyCodeQueryObj = {};
  if (queryObj.inviteCode) keyCodeQueryObj["inviteCode"] = queryObj.inviteCode;
  if (queryObj.keyCode) keyCodeQueryObj["keyCode"] = queryObj.keyCode;
  if (queryObj.shareCode) keyCodeQueryObj["shareCode"] = queryObj.shareCode;
  if (queryObj.playlistShareCode)
    keyCodeQueryObj["playlistShareCode"] = queryObj.playlistShareCode;
  if (queryObj.channelShareCode)
    keyCodeQueryObj["channelShareCode"] = queryObj.channelShareCode;
  for (const [key, value] of Object.entries(UiMode)) {
    if (queryObj[value] === "" || queryObj[value] === "true") {
      keyCodeQueryObj[value] = true;
    }
  }
  if (toInt(time) !== null) keyCodeQueryObj["time"] = time;
  return keyCodeQueryObj;
};
export const objectToQueryString = (obj) => {
  let params = Object.keys(obj)
    .reduce(function (a, k) {
      a.push(k + "=" + encodeURIComponent(obj[k]));
      return a;
    }, [])
    .join("&");
  return params.length > 0 ? "?" + params : "";
};
export const getIntParam = (param) => {
  return toInt(getParam(param));
};
export const getParam = (param) => {
  return window?.router?.state?.matches[0]?.params[param];
};







export const getQueryParamsFromPath = (path) => {
  try {
    const url = new URL(path, window.location.origin);
    return {
      ...Object.fromEntries(
        typeof window === "undefined" ? {} : url.searchParams.entries()
      ),
    };
  } catch (e) {
    console.warn(e);
  }
  return {};
};
export const splitPathIntoArray = (path) => {
  return path.trim().match(/[^\/]+/g) || [];
};
export const assetsBaseUrl = `https://${isTestEnv ? "assets-dev" : "assets"}.pixelmixer.com`;

