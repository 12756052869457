/*global chrome*/
import { Amplify, Auth } from "aws-amplify";
import { isTestEnv } from "../util/URLUtils";
import { logError, logInfo } from "./ServiceUtil";

export const oauthHostname =
  typeof window !== "undefined" &&
  `www${window.location.hostname.substring(
    window.location.hostname.indexOf("."),
    window.location.hostname.length
  )}`;

export const configureAmplify = (useToken = false, oauthOverrides = {}) => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: "us-west-2",
      userPoolId: isTestEnv ? "us-west-2_JpbAzdptt" : "us-west-2_Bekkz1let",
      userPoolWebClientId: isTestEnv
        ? "7i5ut48idomd83nu4q9t4usvgl"
        : "77imq6jtqtvmpocnjjcl5ht1mu",
      oauth: {
        domain: isTestEnv ? "auth-dev.pixelmixer.com" : "auth.pixelmixer.com",
        scope: ["email", "openid"],
        redirectSignIn: `https://${oauthHostname}/oauthLogin${
          useToken ? "/token" : ""
        }`,
        redirectSignOut: `https://${oauthHostname}/?redirectUrl=${
          typeof window !== "undefined"
            ? encodeURIComponent(window.location.origin)
            : ""
        }`,
        responseType: useToken ? "token" : "code", // 'code' or 'token', NOTE: a REFRESH token will only be generated when the responseType is code
        ...oauthOverrides,
      },
    },
  });
};

let signedInUser = undefined;
//Uncomment to debug Amplify:
// if (typeof window !== "undefined") window.LOG_LEVEL = "DEBUG";

export const signinCognito = async (username, password) => {
  try {
    signedInUser = await Auth.signIn(username.toLowerCase(), password);
    logInfo("signedInUser", { challengeName: signedInUser.challengeName });

    if (
      signedInUser.challengeName === "SMS_MFA" ||
      signedInUser.challengeName === "SOFTWARE_TOKEN_MFA"
    ) {
      // You need to get the code from the UI inputs
      // and then trigger the following function with a button click
      //    const code = getCodeFromUserInput();
      // If MFA is enabled, sign-in should be confirmed with the confirmation code
      // const loggedUser = await Auth.confirmSignIn(
      //     user,   // Return object from Auth.signIn()
      //     code,   // Confirmation code
      //     mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      // );
    } else if (signedInUser.challengeName === "NEW_PASSWORD_REQUIRED") {
      const { requiredAttributes } = signedInUser.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
      // You need to get the new password and required attributes from the UI inputs
      // and then trigger the following function with a button click
      // For example, the email and phone_number are required attributes
      // const { username, email, phone_number } = getInfoFromUserInput();
      // const loggedUser = await Auth.completeNewPassword(
      //     user,               // the Cognito User Object
      //     newPassword,       // the new password
      //     // OPTIONAL, the required attributes
      //     {
      //         email,
      //         phone_number,
      //     }
      // );
    } else if (signedInUser.challengeName === "MFA_SETUP") {
      // This happens when the MFA method is TOTP
      // The user needs to setup the TOTP before using it
      // More info please check the Enabling MFA part
      Auth.setupTOTP(signedInUser);
    } else {
      // The signedInUser directly signs in
      const cred = await Auth.currentSession();
    }
    return signedInUser;
  } catch (cognitorError) {
    logError("signinCognito", {}, cognitorError);
    const error = {
      userMessage: cognitorError.message,
      errorCode: cognitorError.code,
    };
    if (cognitorError.code === "UserNotConfirmedException") {
      // The error happens if the user didn't finish the confirmation step when signing up
      // In this case you need to resend the code and confirm the user
      // About how to resend the code and confirm the user, please check the signUp part
    } else if (cognitorError.code === "PasswordResetRequiredException") {
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
    } else if (cognitorError.code === "NotAuthorizedException") {
      // The error happens when the incorrect password is provided
      if (
        cognitorError.message.toLowerCase().indexOf("temporary password") >= 0
      )
        error.errorCode = "PasswordResetRequiredException";
    } else if (cognitorError.code === "UserNotFoundException") {
      // The error happens when the supplied username/email does not exist in the Cognito user pool
    }
    throw error;
  }
};

export const signoutCognito = async () => {
  try {
    if (await getCognitoUser()) await Auth.signOut();
  } catch (error) {
    logError("signoutCognito", {}, error);
  }
};

export const signinOauth = async (provider, origin, pathname, search, hash) => {
  return await Auth.federatedSignIn({
    provider, //ie. CognitoHostedUIIdentityProvider.Google
    customState: JSON.stringify({
      origin,
      pathname: pathname && !pathname?.includes("/login") ? pathname : "/",
      search,
      hash,
    }),
  });
};

/**
 * This method is a workaround because Google oAuth Sign in is for some reason
 * not working when Amplify is configured with responseType: "token" AND
 * invoking signinOauth("Google") from PixelMixerApp (from site-app works fine)
 */
export const redirectToGoogleSignIn = async () => {
  // Make sure the user is fully signed out before redirecting them to sign back in:
  // This avoids a scenario where the user is signed in to app and site with diff creds
  // particularly from GoogleSignInRequired scenarios
  try {
    await signoutCognito();
  } catch (e) {
    logError("signoutCognito", {}, e);
  }
  if (window)
    window.location = `https://${oauthHostname}${generateGoogleSignInPath()}`;
};

export const generateGoogleSignInPath = (redirectPathname) => {
  const { origin, search, pathname, hash } = window.location;
  redirectPathname = redirectPathname || pathname;
  redirectPathname = !redirectPathname.includes("/login")
    ? redirectPathname
    : "";
  return `/oauthSignIn?provider=Google${
    origin ? "&origin=" + encodeURIComponent(origin) : ""
  }${
    redirectPathname ? "&pathname=" + encodeURIComponent(redirectPathname) : ""
  }${search ? "&search=" + encodeURIComponent(search) : ""}${
    hash ? "&hash=" + encodeURIComponent(hash) : ""
  }`;
};

export const getCognitoUser = async () => {
  return await Auth.currentAuthenticatedUser()
    .then((user) => user)
    .catch(() => null);
};

export const getCognitoCredentials = async () => {
  try {
    if (await getCognitoUser()) return await Auth.currentSession();
  } catch (e) {
    logError("getCognitoCredentials", {}, e);
  }
};

export const getCognitoSubject = async () => {
  const cred = await getCognitoCredentials();
  return cred ? cred.accessToken.payload.sub : null;
};

export const getCognitoIdToken = async () => {
  const cred = await getCognitoCredentials();
  return cred ? cred.idToken.jwtToken : null;
};

export const getCognitoAccessToken = async () => {
  const cred = await getCognitoCredentials();
  return cred ? cred.accessToken.jwtToken : null;
};

export const getAuthorizationHeaderValue = async () => {
  const token = await getCognitoAccessToken();
  return token ? "Bearer " + token : null;
};

export const getIdentityHeaderValue = async () => {
  const token = await getCognitoIdToken();
  return token ? "Bearer " + token : null;
};

export const rememberDevice = async (value) => {
  const user = await getCognitoUser();
  if (user)
    value
      ? user.setDeviceStatusRemembered()
      : user.setDeviceStatusNotRemembered();
};

export const getDeviceKey = async () => {
  const user = await getCognitoUser();
  return user ? user.deviceKey() : null;
};

export const changeCognitoPassword = async (currentPassword, newPassword) => {
  const user = await getCognitoUser();
  logInfo("signedInUser", signedInUser);
  return await Auth.changePassword(user, currentPassword, newPassword)
    .then((user) => {
      logInfo("complete new password", user);
      if (user.challengeName === "SMS_MFA") {
        logInfo("confirmSignIn", user);
      } else if (user.challengeName === "MFA_SETUP") {
        logInfo("TOTP setup", user.challengeParam);
      } else {
        logInfo(user);
      }
      return user;
    })
    .catch((cognitoError) => {
      let { message, code } = cognitoError;
      if (message.indexOf(":") > 0)
        message = message.substring(message.indexOf(":"));
      const error = {
        userMessage: message,
        errorCode: code,
      };
      throw error;
    });
};

export const completeCognitoPasswordReset = async (password) => {
  // const { given_name, name, family_name, email } = signedInUser.attributes;
  logInfo("signedInUser", signedInUser);
  return await Auth.completeNewPassword(signedInUser, password, {})
    .then((user) => {
      logInfo("complete new password", user);
      if (user.challengeName === "SMS_MFA") {
        logInfo("confirmSignIn", user);
      } else if (user.challengeName === "MFA_SETUP") {
        logInfo("TOTP setup", user);
      } else {
        logInfo(user);
      }
      return user;
    })
    .catch((cognitoError) => {
      let { message, code } = cognitoError;
      if (message.indexOf(":") > 0)
        message = message.substring(message.indexOf(":"));
      const error = {
        userMessage: message,
        errorCode: code,
      };
      throw error;
    });
};

export const refreshToken = (token) => {
  fetch("https://cognito-idp.us-west-2.amazonaws.com/", {
    headers: {
      "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      "Content-Type": "application/x-amz-json-1.1",
    },
    mode: "cors",
    cache: "no-cache",
    method: "POST",
    body: JSON.stringify({
      ClientId: "77imq6jtqtvmpocnjjcl5ht1mu",
      AuthFlow: "REFRESH_TOKEN_AUTH",
      AuthParameters: {
        REFRESH_TOKEN: token,
        //SECRET_HASH: "your_secret", // In case you have configured client secret
      },
    }),
  }).then((res) => {
    return res.json(); // this will give jwt id and access tokens
  });
};
